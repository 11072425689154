import React from "react";
import { Dropdown as Dropdow } from "semantic-ui-react";

import "./Dropdown.css";

const Dropdown = props => <Dropdow {...props}>{props.children}</Dropdow>;
const DropdownMenu = props => (
  <Dropdow.Menu {...props}>{props.children}</Dropdow.Menu>
);
const DropdownItem = props => (
  <Dropdow.Item {...props}>{props.children}</Dropdow.Item>
);
const DropdownDivider = props => (
  <Dropdow.Divider {...props}>{props.children}</Dropdow.Divider>
);

export { Dropdown, DropdownMenu, DropdownItem, DropdownDivider };
