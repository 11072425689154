import React from "react";
import { Container as Containe } from "semantic-ui-react";

import "./Container.css";

const Container = props => <Containe {...props}>{props.children}</Containe>;
const ContainerFluid = props => (
  <Containe fluid {...props}>
    {props.children}
  </Containe>
);
const ContainerFull = props => (
  <Containe fluid className="trp-container-full" {...props}>
    {props.children}
  </Containe>
);

export { Container, ContainerFluid, ContainerFull };
