import React from "react";
import { ButtonGroup as ButtonGrou } from "semantic-ui-react";

import "./ButtonGroup.css";

const ButtonGroup = props => (
  <ButtonGrou {...props}>{props.children}</ButtonGrou>
);

export { ButtonGroup };
