import React from "react";
// import Recommendations from "./pages/recommendations/Recommendations";
// import CardGrid from "./components/CardGrid";
import Test from "./components/trp-ui/Test/Test";

import "./App.css";

const App = () => {
  return (
    <>
      <Test></Test>;
    </>
  );
};

export default App;
