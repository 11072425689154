import React from "react";

import FakeData from "./FakeData";

import {
  ButtonStandart,
  ButtonPrimary,
  ButtonSecondary,
  ButtonPositive,
  ButtonNegative,
  ButtonGroup,
  Container,
  ContainerFluid,
  ContainerFull,
  Divider,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownDivider,
  Flag,
  Header,
  HeaderSub,
  Input,
  Icon,
  Segment,
  SegmentGroup,
  Select
} from "../index";

import "./Test.css";

const Test = props => {
  return (
    <ContainerFull>
      <Header as="h1"> TRP-UI Test Page</Header>
      <Divider />
      <ContainerFluid>
        <Container>
          <Header
            as="h2"
            textAlign="center"
            content="Divider"
            subheader="Simple divider"
          ></Header>
          <SegmentGroup>
            <Segment>
              <Divider vertical>1</Divider>
              <Divider />
            </Segment>
            <Segment>
              <div>a</div>
              <Divider vertical>2</Divider>
              <div>b</div>
            </Segment>
          </SegmentGroup>
          <Divider />
        </Container>
        <Container>
          <Header as="h2" textAlign="center">
            Buttons
            <HeaderSub>Simple buttons</HeaderSub>
          </Header>
          <ButtonStandart>ButtonStandart</ButtonStandart>
          <ButtonPrimary>ButtonPrimary</ButtonPrimary>
          <ButtonSecondary>ButtonSecondary</ButtonSecondary>
          <ButtonPositive>ButtonPositive</ButtonPositive>
          <ButtonNegative>ButtonNegative</ButtonNegative>
          <Divider />
        </Container>
        <Container>
          <Header as="h2" textAlign="center">
            Button Group
            <HeaderSub>Vertical button group</HeaderSub>
          </Header>
          <ButtonGroup vertical>
            <ButtonStandart>ButtonStandart</ButtonStandart>
            <ButtonPrimary>ButtonPrimary</ButtonPrimary>
            <ButtonSecondary>ButtonSecondary</ButtonSecondary>
            <ButtonPositive>ButtonPositive</ButtonPositive>
            <ButtonNegative>ButtonNegative</ButtonNegative>
          </ButtonGroup>
          <Divider />
        </Container>
        <Container>
          <Header as="h2" textAlign="center">
            Flags
            <HeaderSub>Simple Flags</HeaderSub>
          </Header>
          <Flag name="tr"></Flag>
          <Flag name="gb"></Flag>
          <Flag name="us"></Flag>
          <Flag name="ca"></Flag>
          <Flag name="fr"></Flag>
          <Flag name="it"></Flag>
          <Flag name="ru"></Flag>
          <Flag name="au"></Flag>
          <Flag name="br"></Flag>
        </Container>
        <Container>
          <Header as="h2" textAlign="center">
            Inputs
            <HeaderSub>Simple Inputs</HeaderSub>
          </Header>
          <Input placeholder="Standart..." />
          <Input disabled placeholder="Disabled..." />
          <Input error placeholder="Error..." />
          <Input placeholder="Loading..." loading />
          <Input icon="user" iconPosition="left" placeholder="Username" />
          <Input icon placeholder="Circular Icon...">
            <input />
            <Icon name="search" inverted circular link />
          </Input>
          <Input iconPosition="left" placeholder="Email">
            <Icon name="at" />
            <input />
          </Input>
          <Input label="http://" placeholder="mysite.com" />
          <Input
            label={<Dropdown defaultValue=".com" options={FakeData.domains} />}
            labelPosition="right"
            placeholder="Find domain"
          />
          <Divider />
        </Container>

        <Container>
          <Header as="h2" textAlign="center">
            Dropdown
            <HeaderSub>Simple Dropdowns</HeaderSub>
          </Header>
          <Dropdown
            placeholder="Select Friend"
            selection
            options={FakeData.friendOptions}
          />
          <span>
            Show me posts by{" "}
            <Dropdown
              inline
              options={FakeData.friendOptions}
              defaultValue={FakeData.friendOptions[0].value}
            />
          </span>
          <Dropdown text="File">
            <DropdownMenu>
              <DropdownItem text="New" />
              <DropdownItem text="Open..." description="ctrl + o" />
              <DropdownItem text="Save as..." description="ctrl + s" />
              <DropdownItem text="Rename" description="ctrl + r" />
              <DropdownItem text="Make a copy" />
              <DropdownItem icon="folder" text="Move to folder" />
              <DropdownItem icon="trash" text="Move to trash" />
              <DropdownDivider />
              <DropdownItem text="Download As..." />
              <DropdownItem text="Publish To Web" />
              <DropdownItem text="E-mail Collaborators" />
            </DropdownMenu>
          </Dropdown>
          <Dropdown
            id="asd"
            placeholder="Select Country"
            search
            clearable
            selection
            options={FakeData.countryOptions}
          />
          <Dropdown
            button
            className="icon"
            floating
            labeled
            icon="world"
            placeholder="Select Country"
            search
            options={FakeData.countryOptions}
          />

          <ButtonGroup color="teal">
            <ButtonPrimary>Save</ButtonPrimary>
            <Dropdown
              className="button icon"
              floating
              options={FakeData.postOptions}
              trigger={<React.Fragment />}
            />
          </ButtonGroup>

          <Dropdown
            text="Dropdown"
            options={FakeData.postOptions}
            simple
            item
          />
          <Dropdown
            placeholder="Skills"
            fluid
            multiple
            search
            selection
            options={FakeData.multipleSkills}
          />
          <Divider />
        </Container>
        <Container>
          <Header as="h2" textAlign="center">
            Select
            <HeaderSub>Simple {"<Dropdown selection />"}</HeaderSub>
          </Header>
          <Select
            placeholder="Select your country"
            options={FakeData.countryOptions}
          />
          <Divider />
        </Container>
      </ContainerFluid>
    </ContainerFull>
  );
};

export default Test;
