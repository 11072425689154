import React from "react";
import { Button } from "semantic-ui-react";

import "./Button.css";

const BaseButton = props => {
  /*
  let bprops = {
    loading: props.loading ? true : false,
    text: props.text ? props.text : "",
    click: props.click ? props.click : () => {}
  };
  */

  return (
    <Button
      /*loading={bprops.loading}
      content={bprops.text}
      onClick={bprops.click}*/
      {...props}
    >
      {props.children}
    </Button>
  );
};

const ButtonStandart = props => <BaseButton {...props}></BaseButton>;
const ButtonPrimary = props => <BaseButton {...props} primary></BaseButton>;
const ButtonSecondary = props => <BaseButton {...props} secondary></BaseButton>;
const ButtonPositive = props => <BaseButton {...props} positive></BaseButton>;
const ButtonNegative = props => <BaseButton {...props} negative></BaseButton>;

export {
  ButtonStandart,
  ButtonPrimary,
  ButtonSecondary,
  ButtonPositive,
  ButtonNegative
};
