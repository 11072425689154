import React from "react";
import { Header as Heade } from "semantic-ui-react";

import "./Header.css";

const Header = props => <Heade {...props}>{props.children}</Heade>;
const HeaderSub = props => (
  <Heade.Subheader {...props}>{props.children}</Heade.Subheader>
);

export { Header, HeaderSub };
